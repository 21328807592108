.contact-header {
  z-index: 2;
  font-family: 'PTBandocheB';
}

.contact-primary {
  font-family: 'PTBandocheB';
  color: #007bff;
}

.contact-muted {
  color: #6c757d;

}

.contact-button {
  color: black;
  font-family: 'PTBandocheB';
}