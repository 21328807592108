.button_css {
    background-color: white;
    border-radius: 10px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, transform 0.2s;
    align-items: center;
}

.button_css:hover {
    transform: translateY(-2px);
}