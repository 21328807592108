@media (min-width: 576px) {
    .col-sm-6 {
        flex: 0 0 auto;
        width: 46%;
    }
}

@media (min-width: 992px) {
    .col-lg-4 {
        flex: 0 0 auto;
        width: 31.6%;
    }
}