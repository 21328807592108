.container-full {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.page-layout {
  flex: 1;
  margin: 0;
  padding: 0;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  background: #fffdfd;
  font-size: 14px;
  color: #000;
}


.swiper {
  width: 100%;
  padding-bottom: 50px;
  perspective: 1000px;

}

.swiper-slide {
  background: #fff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2),
    /* 기본적인 그림자 */
    0px 6px 6px rgba(0, 0, 0, 0.15);
  /* 추가적인 부드러운 그림자 */
  transition: transform 0.5s ease;
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 500px;
}

.swiper-slide-prev,
.swiper-slide-next {
  opacity: 0.5;
  /* 양 옆 슬라이드를 투명하게 만듦 */
}

.swiper-slide-active {
  transform: scale(1.1) rotateY(15deg);
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4),
    0px 10px 10px rgba(0, 0, 0, 0.3);
  /* 더 강한 그림자 효과 */
  opacity: 1;
}


.swiper-slide img {
  display: block;
}