.mypage-content__wrapper {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
}

.mypage-content__title-wrapper h5.mypage-content__title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}

/* 사용자 정보 표시 */
.mypage-content__user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.mypage-content__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-input.read-only:focus {
  outline: none; 
  box-shadow: none;
}

.mypage-profile-nav{
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
}
