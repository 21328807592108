.header-logout-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* 메뉴 컨테이너 스타일 */
.menu-container {
  position: relative;
}

/* 상위 메뉴 항목 스타일 */
.nav-item {
  position: relative;
}

/* 기본적으로 dropdown-menu-container 숨기기 */
.dropdown-menu-container {
  display: none;
  position: absolute;
  min-width: 200px;
  z-index: 1000;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

/* 네비게이션 항목에 호버했을 때 하위 메뉴 표시 */
.nav-item:hover .dropdown-menu-container {
  display: block;
  opacity: 1;
}

/* 각 dropdown-section 스타일 */
.dropdown-section {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* 하위 항목 스타일 */
.dropdown-section>* {
  margin-bottom: 20px;
  color: #333;
  text-decoration: none;
  white-space: nowrap;
}

.dropdown-section>*:hover {
  color: #007bff;
  font-weight: bold;
}

/* 각 dropdown-item 스타일 (기존 스타일 유지) */
.dropdown-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

/* 창 크기 줄었을 때 메뉴 스타일 */
.navbar-menu {
  display: flex;
  justify-content: space-between;
  width: 100%; /* 전체 폭을 차지하도록 설정 */
  padding: 1rem;
}

/* resized-menu-item 스타일 */
.resized-menu-item {
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
}

.resized-menu-item>h5 {
  cursor: default;
}

.resized-menu-item h5 {
  color: #007bff;
  font-weight: bold;
  margin: 0 0 20px 0;
}

.resized-menu-item a {
  color: #333;
  text-decoration: none;
  margin-bottom: 15px;
}

.resized-menu-item a:hover {
  color: #007bff;
  font-weight: bold;
}

.custom-tooltip .tooltip-inner {
  background-color: rgb(89, 88, 88);
  max-width: 250px;
  text-align: left;
}
.custom-tooltip.bs-tooltip-bottom .arrow::before {
  border-bottom-color: white;
}

.header-logo-image {
  width: 110px;  /* 원하는 이미지 크기 */
  height: auto; /* 비율 유지 */
}
