.custom-select-container {
  width: 100%;
}

.form-floating {
  position: relative;
}

.custom-select-header {
  width: 100%;
  padding: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 58px;
}

.custom-select-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ced4da;
  border-top: none;
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 250px;
  overflow-y: auto;
  z-index: 1000;
}

.custom-select-options li {
  padding: 10px 15px;
  cursor: pointer;
  color: #555;
}

.custom-select-options li:hover {
  background-color: #f8f9fa;
  color: black;
}

.arrow {
  border: solid #6c757d;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  transition: transform 0.3s ease;
}

.arrow.open {
  transform: rotate(-135deg);
}

.worksingle-footer {
  margin-bottom: 16px;
}