/* 공통 스타일을 직접 CSS로 반영 */
.filledStyle {
    background-color: black;
    color: white;
  }
  
  .alignCenter {
    display: flex;
    -webkit-display: flex;
    flex-direction: column;
    -ms-flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  /* DragDrop 스타일 */
  .DragDrop {
    width: 100%;
    /* height: 100vh; */
    display: flex;
    -webkit-display: flex;
    flex-direction: column;
    -ms-flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .DragDrop-File {
    width: 500px;
    height: 200px;
    border: 2px solid black;
    border-radius: 10px;
    display: flex;
    -webkit-display: flex;
    flex-direction: column;
    -ms-flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.12s ease-in;
  }
  
  .DragDrop-File-Dragging {
    background-color: black;
    color: white;
  }
  
  .DragDrop-Files {
    margin-top: 1rem;
  }
  
  .DragDrop-Files > div {
    width: 300px;
    padding: 8px;
    border: 1px solid black;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }
  
  .DragDrop-Files-Filter {
    cursor: pointer;
  }
  
  .DragDrop-Files-Filter:hover {
    opacity: 0.7;
  }
  