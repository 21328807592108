.post-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e0e0e0; /* 버튼 테두리 */
  border-radius: 10px; /* 둥근 모서리 */
  padding: 10px 20px; /* 내부 여백 */
  background-color: white; /* 배경색 */
  cursor: pointer; /* 마우스 커서 */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* 그림자 */
  transition: box-shadow 0.2s ease; /* hover 효과 */
}

.post-button:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); /* hover 시 더 강한 그림자 */
}

.button-icon {
  font-size: 24px; /* 아이콘 크기 */
  margin-right: 8px; /* 아이콘과 텍스트 사이 간격 */
}

.button-text {
  font-size: 18px; /* 텍스트 크기 */
}
