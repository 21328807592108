.app-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}


.mypage-wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

.mypage-container {
  display: flex;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
}

.mypage-sidebar {
  flex: 1;
  /* border-right: 1px solid #e9ecef; */
}

.mypage-content {
  flex: 3;
  padding: 20px;
  min-width: 850px;
  min-height: 600px; 


}