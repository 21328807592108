textarea {
  resize: none;
}

.comment {
  position: relative;
  border-bottom: 1px solid #e0e0e0;
  padding: 15px 0;
}

.comment:last-child {
  border-bottom: none;
}

.menu-icon-container {
  position: relative;
}

.menu-icon {
  cursor: pointer;
  font-weight: bold;
}

.dropdown-menu {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 0;
  display: none;
  min-width: 100px;
  z-index: 1000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 5px 10px;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.comment-author {
  font-weight: bold;
}

.comment-time {
  font-size: 0.8em;
  color: #888;
}

.comment-content {
  margin-top: 10px;
}

.reply-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  padding: 0;
}

.reply {
  position: relative;
  border-bottom: 1px solid #e0e0e0;
  padding: 15px 0 15px 20px;
  margin-left: 20px;
}

.reply:last-child {
  border-bottom: none;
}

.form-group {
  position: relative;
}

.form-group .btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.leave-comment textarea {
  resize: none;
  padding-bottom: 40px;
}

.textarea-container {
  position: relative;
}
.textarea-container textarea {
  width: 100%;
  padding-right: 120px; /* 버튼 너비에 따라 조정 */
  box-sizing: border-box;
}

.edit-buttons {
  position: absolute;
  bottom: 10px; 
  right: 10px;  
  display: flex;
  gap: 1px; /* 버튼 간 간격 */
}

/* 버튼 스타일 */
.edit-buttons button {
  padding: 5px 10px;
  font-size: 0.9em;
}