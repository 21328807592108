.password-button, .cancel-button {
  width: 100%;
  padding: 12px 16px;
  margin-bottom: 15px;
  border: none;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}


.password-button {
    border: 1px solid #6266EA;
    background-color: #6266EA;
    color: #FFFFFF;
  }
.password-button:hover {
  background-color: #4a4fb7;
  transform: scale(1.02);
}

.cancel-button {
  border: 1px solid #ff6b6b;
  background-color: #ff6b6b;
  color: #FFFFFF;
}

.cancel-button:hover {
  background-color: #d55959;
  transform: scale(1.02);
} 

.password-input {
  width: 100%;
  max-width: 500px;
  padding: 5px 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  color: #333;
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.loading-message {
  margin-top: 10px;
  color: blue;
  font-weight: bold;
  font-style: italic;
  text-align: center;
}

.password-reset-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
}


.reset-form, .set-password-form {
  background: #ffffff;
  padding: 30px 40px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.newResetPasswordContent1, .newResetPasswordContent1 {
  display: block;
  margin-bottom: 5px; /* 줄 간격을 추가하고 싶으면 이 속성으로 간격 조절 */
}

.password-error-message {
  color: #ff4d4d;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 15px;
  text-align: center;
}

.password-button:disabled {
  background-color: lightgray;
  border: 1px solid lightgray;
  cursor: not-allowed;
}
