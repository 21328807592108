.post-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.post-item {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  background-color: #fff;
}

.post-link {
  text-decoration: none;
  color: inherit;
}

.post-status {
  display: inline-block;
  background-color: #4232C2;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.8em;
  margin-bottom: 10px;
}

.post-title {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #333;
}

.post-content {
  color: #666;
  /* margin-bottom: 10px; */
}

.post-meta {
  display: flex;
  justify-content: space-between;
  color: #888;
  font-size: 0.9em;
}

.post-header {
  display: flex;
  gap: 5px;
  align-items: center; 
}

.post-intro{
  font-size: 0.9em;
  margin-bottom: 5px;
}

.post-status-completed {
  background-color: #808080;
}

.search-form {
  display: flex;
  align-items: center; 
  /* gap: 1px; /*버튼, 검색창 간격 */
}

.search-select-container {
  display: flex;
  align-items: center;
  gap: 1px; /* SearchSelect input 간격 */
}

.search-input {
  height: 27px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0 10px;
}

.search-button {
  height: 27px; 
  border: none;
  background-color: #4232C2;
  color: white;
  padding: 0 15px;
  border-radius: 0.25rem;
  cursor: pointer;
  margin-left: 1px;
}

.comment-count {
  color: #4232C2;
}
